/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Header from "./header"
import "./layout.css"

import png_awesomenz_logo from "../images/awesomenzLogo1.png"

function Layout(props) {
  return (
    <>
      <div id="Container" class={props.layoutType}>
        <div id="Header" className="row">
          <div className="headerWrapper">
            <div className="header-objects">
              <span className="has-flyout" id="mobile-menu">
                &#9776;
              </span>
              <Link to="/" id="Logo">
                <img src={png_awesomenz_logo} alt="AwesomeNZ" />
              </Link>
            </div>

            <div id="Navigation" class="row">
              <div id="Menu" class="twenty columns">
                <ul class="nav-bar">
                  <li class="has-flyout">
                    <Link
                      to="/home/new-zealand-tours/milford-sound-tours"
                      class="link"
                    >
                      Milford Sound
                    </Link>
                  </li>
                  <li class="has-flyout">
                    <Link
                      to="/home/new-zealand-tours/mt-cook-tours"
                      class="link"
                    >
                      Mt Cook
                    </Link>
                  </li>
                  <li class="has-flyout">
                    <Link
                      to="/home/new-zealand-tours/bay-of-islands-tours"
                      class="link"
                    >
                      Bay of Islands
                    </Link>
                  </li>
                  <li class="has-flyout">
                    <Link
                      to="/home/new-zealand-tours/hobbiton-tours"
                      class="link"
                    >
                      Hobbiton
                    </Link>
                  </li>
                  <li class="has-flyout">
                    <Link to="/getting-around-nz" class="link">
                      Travel
                    </Link>
                  </li>
                  <li class="has-flyout">
                    <Link to="/contact-us" class="link">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="ResCartSummaryPanel">
              <Link to="/cart/">
                <div id="toggllerButtonCartWrapBG">
                  {" "}
                  <span
                    id="toggllerButtonCartWrap"
                    class="buttonSlideCart ui-state-default ui-corner-all"
                  ></span>
                </div>
              </Link>
              {/*<div id="ResCartSummaryToggler">
            <div id="effectCart" class="ui-widget-content ui-corner-all">
              <div id="ResCartSummary">
                <div class="ElementContainer ">
                  <div class="NoCart"></div>
                </div>
              </div>
            </div>
          </div>*/}
            </div>
          </div>
        </div>
        <div id="stop-slider-moving">
          <div id="Main">
            <div class="slideshow-wrapper">
              <div id="slideshow" class="new-slideshow">
                {props.slideshow}
                {props.hasSliderHandles && (
                  <div id="fssList">
                    <a href="#" id="prev2">
                      <div class="arrow">&nbsp;</div>
                    </a>
                    <a href="#" id="next2">
                      <div class="arrow">&nbsp;</div>
                    </a>
                  </div>
                )}
              </div>
              {props.hasSliderBlurSmall && (
                <div class="slider_blur slider-blur-small">&nbsp;</div>
              )}
              {props.hasSliderBlurLarge && (
                <div class="slider_blur slider-blur-large">&nbsp;</div>
              )}
            </div>
            <div id="Layout" class="row">
              {props.content}
              <div id="Footer">
                <div class="footer-details">
                  <div class="quick-links  ">
                    <ul class=" columns">
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li>
                        <a
                          href="https://www.booking.com/index.html?aid=370994;lang=en;label=banner_728_90"
                          target="_blank"
                        >
                          Accommodation
                        </a>
                      </li>
                      <li>
                        <Link to="/terms">Terms and Conditions</Link>
                      </li>
                    </ul>
                  </div>
                  <div class="logos ">
                    <div class="footer-logo">&nbsp;</div>
                    <div class="copyright">
                      <span>
                        {" "}
                        &#169;{" "}
                        <a href="https://entradatravelgroup.com/">
                          {new Date().getFullYear()} Entrada Travel Group
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </>
  )
}

export default Layout
